export default function InstagramLoginButton() {
  const instagramAppId = process.env.REACT_APP_INSTAGRAM_APP_ID;
  const redirectUri = encodeURIComponent(document.location.href);
  const scope = 'user_profile,user_media';
  const url = `https://api.instagram.com/oauth/authorize?client_id=${instagramAppId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
  return (
    <div>
      <a href={url}>Login Instagram</a>
    </div>
  );
};
