import { Navigate, Outlet, Route, Routes, } from "react-router-dom";

function Login() {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to="facebook" replace={true} />} />
      </Routes>

      <Outlet />
    </>
  );
}

export default Login;
