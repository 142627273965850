import React, { useEffect, useState } from 'react';
import './App.scss';
import axios from 'axios';
import LoginPanel, { AuthInfo } from './LoginPanel';

function App() {
  const [authInfo, setAuthInfo] = useState<AuthInfo>();

  useEffect(() => {
    axios.get("/auth-info").then((resp) => {
      setAuthInfo(resp.data);
    });
  }, []);

  return (
    <div className="App">
      <header>
        <h1>MemberBuy</h1>
      </header>
      {authInfo ? <LoginPanel {...authInfo} /> : (
        <main>
          <p>=== Auth Server ===</p>
        </main>
      )}
      <footer>
        <p>Copyright @ 2023</p>
      </footer>
    </div>
  );
}

export default App;
