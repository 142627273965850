import InstagramLoginButton from "../utils/InstagramSdk";

function InstagramLogin() {
  return (
    <>
      <InstagramLoginButton />
    </>
  );
}

export default InstagramLogin;
