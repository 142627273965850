import { Route, Routes } from "react-router-dom";
import Login from "../login/Login";
import App from "../app/App";
import FacebookLogin from "../login/FacebookLogin";
import InstagramLogin from "../login/InstagramLogin";

export default function Root() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="login" element={<Login />}>
        <Route path="facebook" element={<FacebookLogin />} />
        <Route path="instagram" element={<InstagramLogin />} />
      </Route>
    </Routes>
  );
}
