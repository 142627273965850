import FacebookLoginButton from "../utils/FacebookSdk";

function FacebookLogin() {
  return (
    <>
      <FacebookLoginButton />
    </>
  );
}

export default FacebookLogin;
