import { useEffect } from "react";

export const initFacebookSdk = () => {
  return new Promise((resolve, reject) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v18.0'
      });
      // Resolve the promise when the SDK is loaded
      resolve(0);
    };
  });
};

export const getFacebookLoginStatus = () => {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response: any) => {
      resolve(response);
    });
  });
};

export const loginFacebook = () => {
  return new Promise((resolve, reject) => {
    window.FB.login((response: any) => {
      resolve(response)
    })
  })
};

export function FacebookInit() {
  useEffect(() => {
    console.log("Started use effect");
    initFacebookSdk().then(() => {
      getFacebookLoginStatus().then((response) => {
        if (response == null) {
          console.log("No login status for the person");
        } else {
          console.log(response);
        }
      });
    });
  }, []);
};

export default function FacebookLoginButton() {
  FacebookInit();

  function login() {
    console.log("reached log in button");
    loginFacebook().then((response: any) => {
      console.log(response);
      if (response.status === "connected") {
        console.log("Person is connected");
      } else {
        // something
      }
    });
  }

  return (
    <div>
      <button onClick={login}>Login Facebook</button>
    </div>
  );
};
