import psl from 'psl';
import { useState } from 'react';

export type AuthInfo = {
  confirm_uri: string;
  abort_uri: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  state: string;
};

type Props = AuthInfo;

const LoginPanel: React.FC<Props> = (props) => {
  const [domain,] = useState<string | null>(() => {
    const host = new URL(props.redirect_uri).host;
    return psl.get(host) || host;
  });

  return (
    <main>
      <fieldset>
        <legend>Login</legend>
        <p>An application {domain && (
          <i className='domain'>({domain})</i>
        )} would like to connect to your account.</p>
        <form method="POST">
          <input type="hidden" name="client_id" value={props.client_id} />
          <input type="hidden" name="redirect_uri" value={props.redirect_uri} />
          <input type="hidden" name="response_type" value={props.response_type} />
          <input type="hidden" name="scope" value={props.scope} />
          <input type="hidden" name="state" value={props.state} />
          <button type="submit" formAction={props.confirm_uri} className="primary">allow</button>
          <button type="submit" formAction={props.abort_uri} className="secondary">deny</button>
        </form>
      </fieldset>
    </main>
  );
};

export default LoginPanel;
